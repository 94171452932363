import React from "react"

import { useBet } from "@root/bet_utils/context/bet"
import betStatusPresenter from "@root/bet_utils/presenter/bet_status"
import BetStatus from '@root/bet_utils/BetStatus'

const sportIconContext = require.context("@images", true, /sport--.*\.png/)
const sportIcon = (sport: string): string | null => {
  const key = `./sport--${sport}.png`
  return _.includes(sportIconContext.keys(), key) ? sportIconContext(key) : null
}

export const Wrapper: React.FC = ({ children }) => <div className="textSelectionBet__wrapper">{children}</div>
export const Body: React.FC = ({ children }) => <div className="textSelectionBet__body">{children}</div>
export const Footer: React.FC = ({ children }) => <div className="textSelectionBet__footer">{children}</div>

const prettyDateOrTBD = (bet: TextSelection.Api.Bet) => {
  if (!bet.postponed) {
    return betStatusPresenter.prettyDate(bet)
  }else {
    return "TBD"
  }
}

export const MetaInfo: React.FC<{bet: TextSelection.Api.Bet}> = ({ bet }) => {
  return (
    <div className={`selectionBet__metaInfo`}>
      <span>{prettyDateOrTBD(bet)}</span>
      <span>
      {bet.meta_location}
      <br />
      {bet.meta_link_url && <a href={bet.meta_link_url}>{bet.meta_link_text}</a>}
      </span>
    </div>
  )
}

export const Header: React.FC = () => {
  const bet = useBet() as TextSelection.Api.Bet

  return <div className="textSelectionBet__header">
    <div className="textSelectionBet__headerEventName">{bet.event_name}</div>
    <BetStatus bet={bet} />
  </div>
}
