import React, { useState } from 'react'
import I18n from 'i18n-js'

import Button from './Button'
import Form from "./CommunitySettingsForm"

function CreateCommunity ({
  form_open: formOpen,
  form_params: formParams,
}) {
  const [showForm, setShowForm] = useState(formOpen)

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  return (
    <React.Fragment>
      {showForm
        ? (
          <div className='createCommunity createCommunity--withCommunities'>
            <div className='createCommunity__header'>
              <h2 className='createCommunity__title'>{I18n.t('community.create.title')}</h2>
              <div className='createCommunity__icon icon icon--ui__textCross pointer' onClick={toggleForm} />
            </div>
            <Form {...formParams} />
          </div>
        )
        : (
          <div className='createCommunity createCommunity--noCommunities'>
            <Button className='widget__button' primary role='button' onClick={toggleForm}>
              {I18n.t('community.create.btn_new_community')}
            </Button>
          </div>
        )
      }
    </React.Fragment>

  )
}

export default CreateCommunity
