const pickPresenter = {
  hasPickAtIndex: (bet, pickIndex) => {
    return pickPresenter.isValidPick((bet.picks || [])[pickIndex])
  },
  isValidPick: (pick) => {
    return (!!pick && pick !== 0 && pick !== '' && pick !== '0')
  },
  isSame: (option1, option2) => {
    return (
      pickPresenter.isValidPick(option1) &&
      pickPresenter.isValidPick(option2) &&
      (
        (option1 === option2) ||
        (option1.id && option2.id && ('' + option1.id === '' + option2.id)) ||
        false))
  }
}

export default pickPresenter
