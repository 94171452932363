import React, { useState } from 'react'
import I18n from 'i18n-js'
import _ from 'lodash'

import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import PostponedInfo from '@root/bet_utils/PostponedInfo'
import { pickPresenter, scoringPresenter } from './presenter'
import BetStatus from '@root/bet_utils/BetStatus'
import { addSecondsLeftToBet } from '@root/bet_utils/use_deadline_countdown'
import { TeamContext, PickContext } from './context'
import BetContext from '@root/bet_utils/context/bet'
import DisplaySaveResult, { createOkResult, createErrorResult } from '@root/bet_utils/DisplaySaveResult'
import savePicks, { throttledSavePicks } from '@root/bet_utils/save_picks'
import Team from './Team'
import Result from "./Result"
import OptionalAdditionalInfo from '@root/bet_utils/OptionalAdditionalInfo'
import CancelledInfo from '@root/bet_utils/CancelledInfo'

const POINTS_CORRECT = 5
const POINTS_DIFFERENCE = 3
const POINTS_WIN_DRAW_LOSE = 2

const ScoreBet = ({ bet, authenticity_token: authenticityToken }) => {
  addSecondsLeftToBet(bet) // seconds left automatically calculated
  const [ percPlayersSamePick, __setPercPlayersSamePick ] = useState(
    _.isUndefined(bet.perc_players_same_pick)
      ? -1
      : (bet.perc_players_same_pick)
  )

  const [ pickTeam0, setPickTeam0 ] = useState(pickPresenter.getPickAtIndex(bet, 0))
  const [ pickTeam1, setPickTeam1 ] = useState(pickPresenter.getPickAtIndex(bet, 1))
  const notBothPicked = pickTeam0 === -1 || pickTeam1 === -1
  bet.picks = [ pickTeam0, pickTeam1 ]
  const [ displaySaveResult, setDisplaySaveResult ] = useState(null)

  const displayPercPlayersSamePick = betStatusPresenter.canEdit(bet) && percPlayersSamePick !== -1

  const setPercPlayersSamePickFromSaveResponse = (resp) => {
    if (resp && !_.isUndefined(resp.perc_players_same_pick)) {
      __setPercPlayersSamePick(resp.perc_players_same_pick)
    }
  }

  const setPickTeam0AndSave = (newPick) => {
    if (_.isNumber(pickTeam1) && newPick > -1 && pickTeam1 > -1) { // onlys save when other pick is valid
      throttledSavePicks(bet, authenticityToken, [newPick, pickTeam1],
        /* OK    */ (msg, fullResp) => {
          setDisplaySaveResult(createOkResult(msg))
          setPercPlayersSamePickFromSaveResponse(fullResp)
        },
        /* ERROR */ (errorMsg) => {
          setDisplaySaveResult(createErrorResult(errorMsg))
        })
    }
    setPickTeam0(newPick)
  }
  const setPickTeam1AndSave = (newPick) => {
    if (_.isNumber(pickTeam0) && newPick > -1 && pickTeam0 > -1) { // onlys save when other pick is valid
      throttledSavePicks(bet, authenticityToken, [pickTeam0, newPick],
        /* OK    */ (msg, fullResp) => {
          setDisplaySaveResult(createOkResult(msg))
          setPercPlayersSamePickFromSaveResponse(fullResp)
        },
        /* ERROR */ (errorMsg) => { setDisplaySaveResult(createErrorResult(errorMsg)) })
    }
    setPickTeam1(newPick)
  }

  return (
    <BetContext.Provider value={bet}>
      <div className={`scoreBet ${notBothPicked ? "scoreBet--notBothPicke" : ""}`}>
        <div className='scoreBet__header'>
          <span className='scoreBet__header__meta'>{betStatusPresenter.prettyDate(bet)}</span>
          <BetStatus />
        </div>
        <CancelledInfo />
        <OptionalAdditionalInfo />
        <PostponedInfo />
        <div className={'scoreBet__metaInfo'}>
          {bet.meta_location && <span>{bet.meta_location}</span>}
          {bet.demo && !notBothPicked && <span className='scoreBet__demoIndicator'></span>}
        </div>
        <div className='scoreBet__teams'>
          <TeamContext.Provider value={bet.teams[0]}>
            <PickContext.Provider value={{ value: pickTeam0, setValue: setPickTeam0AndSave }}>
              <Team />
            </PickContext.Provider>
          </TeamContext.Provider>
          <TeamContext.Provider value={bet.teams[1]}>
            <PickContext.Provider value={{ value: pickTeam1, setValue: setPickTeam1AndSave }}>
              <Team />
            </PickContext.Provider>
          </TeamContext.Provider>
        </div>
        {displayPercPlayersSamePick && <div className='scoreBet__percPlayersSamePick'>
          {I18n.t('bet.perc_players_same_pick', { perc: percPlayersSamePick })}</div>}
        <DisplaySaveResult classPrefix='scoreBet' saveResult={displaySaveResult} />
        <div className='scoreBet__footer'>
          {betStatusPresenter.isMatchOverAndWaitingForScores(bet) &&
            <div>{I18n.t('bet.scoring.waiting')}</div>}
          {betStatusPresenter.isMatchOverAndScored(bet) &&
            <Result />}
        </div>
      </div>
    </BetContext.Provider>
  )
}

export default ScoreBet
