import React, { useCallback, useState } from "react"

const BurgerMenu = () => {

  const [open, setOpen] = useState(false)
  
  const toggleBurgerMenu = useCallback(() => {
    const dropdown = document.querySelector(".js-burgerDropdown")
    if (!dropdown) {
      console.error("No dropdown found!")
      return
    }
    setOpen(!open)
    dropdown.classList.toggle("neo__clientNavigation__burgerDropdown--open")
  }, [open])
  
  return <>
    <div className="neo__clientNavigation__burgerMenu">
      {open
        ? <div className="neo__clientNavigation__icon--close" onClick={toggleBurgerMenu}/>
        : <div className="neo__clientNavigation__icon--burgerMenu" onClick={toggleBurgerMenu}/>
      }
    </div>
  </>
}

export default BurgerMenu