import React from 'react'

import imagePresenter from '@root/bet_utils/presenter/image'

import CircleImg from '../CircleImg'
import Name from '../Name'
import FlagImg from "../FlagImg"

const SelectItem = ({ item, select }) => {
  return (
    <div className='selector__selectItem' onClick={select}>
      <CircleImg item={item} thumb />
      <Name item={item} />
      {!imagePresenter.useFlagInstead(item.imageSrc) && <FlagImg item={item} />}
    </div>
  )
}

export default SelectItem
