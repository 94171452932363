import React from "react"

interface Props {
  onlyMobile?: boolean,
  onlyDesktop?: boolean,
  src: string
  href: string
}

const SponsorBox: React.FC<Props> = ({ src, href, onlyMobile, onlyDesktop }) => {
  const className = [
    "sponsorBox__container",
    onlyMobile ? "--mobile" : "",
    onlyDesktop ? "--desktop" : ""
  ].join(" ")

  return <a className={className} href={href} target="_blank">
    <img src={src} />
  </a>
}

export default SponsorBox
