import React, { useState, useCallback } from 'react'
import I18n from 'i18n-js'
import axios from 'axios'

import Button from "./Button"

const HAS_OPTIN = true

const redirectTo = (url) => {
  console.log('redirect to', url)

  if (url !== '') {
    window.location.href = url
    return true
  }

  return false
}

const submitToNeo = (submitUrl, authenticityToken, firstname, lastname, shortenLastname, receiveBetReminders, reminderEmail, marketingOptIn, newsletterOptIn) => {
  return new Promise((resolve, reject) => {
    const data = {
      authenticity_token: authenticityToken,
      firstname: firstname,
      lastname: lastname,
      shorten_lastname: shortenLastname,
      receive_bet_reminders: receiveBetReminders,
      reminder_email: reminderEmail,
      marketing_opt_in: marketingOptIn,
      newsletter_opt_in: newsletterOptIn,
    }

    axios.post(submitUrl, data).then(({data}) => {
      if (data?.error) {
        reject(data.error)
      } else {
        resolve()
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

const NicknameForm = (
  {
    variant, // "complete_profile" | "edit_profile"
    firstname: defaultFirstname,
    lastname: defaultLastname,
    shorten_lastname: shortenLastname,
    terms_url: termsUrl,
    submit_url: submitUrl,
    redirect_after_submit_url: redirectAfterSubmitUrl,
    authenticity_token: authenticityToken,
    receive_bet_reminders: receiveBetReminders,
    reminder_email: defaultReminderEmail,
    marketing_opt_in: marketingOptIn,
    newsletter_opt_in: newsletterOptIn,
  }) => {

  const [firstname, setFirstname] = useState(defaultFirstname || "")
  const [lastname, setLastname] = useState(defaultLastname || "")
  const [reminderEmail, setReminderEmail] = useState(defaultReminderEmail || "")
  const [shortenLastnameChecked, setShortenLastnameChecked] = useState(shortenLastname || false)
  const [optinChecked, setOptInChecked] = useState(false)

  const [optInRequiredError, setOptInRequiredError] = useState(false)
  const [firstnameErrorMsg, setFirstnameErrorMsg] = useState(false)
  const [lastnameErrorMsg, setLastnameErrorMsg] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [receiveBetRemindersChecked, setReceiveBetRemindersChecked] = useState(receiveBetReminders || false)
  const [marketingOptInChecked, setMarketingOptInChecked] = useState(marketingOptIn || false)
  const [newsletterOptInChecked, setNewsletterOptInChecked] = useState(newsletterOptIn || false)

  const submit = useCallback(() => {
    let earlyErrored = false

    if (variant === "complete_profile") {
      if (!firstname) {
        setFirstnameErrorMsg(I18n.t('profiles.errors.firstname_missing'))
        earlyErrored = true
      } else {
        setFirstnameErrorMsg('')
      }

      if (!lastname) {
        setLastnameErrorMsg(I18n.t('profiles.errors.lastname_missing'))
        earlyErrored = true
      } else {
        setLastnameErrorMsg('')
      }
    }

    if (variant === "complete_profile" && HAS_OPTIN && !optinChecked) {
      setOptInRequiredError(true)
      earlyErrored = true
    } else {
      setOptInRequiredError(false)
    }

    if (!earlyErrored) {
      setSubmitting(true)
      submitToNeo(submitUrl,
          authenticityToken,
          firstname,
          lastname,
          shortenLastnameChecked,
          receiveBetRemindersChecked,
          receiveBetRemindersChecked ? reminderEmail : "",
          marketingOptInChecked,
          newsletterOptInChecked
        ).then(() => {
          if (!redirectTo(redirectAfterSubmitUrl)) {
            // could not redirect?
            setSubmitting(false)
          }
        })
        .catch((error) => {
          switch(error.type) {
            case "profile":
              console.error(error.message)
              break
            case "email":
              setEmailErrorMsg(error.message)
              break
            case "firstname":
              setFirstnameErrorMsg(error.message)
              break
            case "lastname":
              setLastnameErrorMsg(error.message)
              break
          }
          setSubmitting(false)
        })
    } else {
      console.log('early error -> don\'t submit anything')
    }
  }, [firstname, lastname, shortenLastnameChecked, receiveBetRemindersChecked, reminderEmail, marketingOptInChecked, newsletterOptInChecked, optinChecked])
  const onKeyDownEmail = useCallback((e) => {
    if (e.key === "Enter") {
      submit()
    }
  }, [firstname, lastname, shortenLastnameChecked, receiveBetRemindersChecked, reminderEmail, marketingOptInChecked, newsletterOptInChecked, optinChecked])

  const onChangeFirstname = useCallback((e) => {
    setFirstname(e.target.value)
  }, [])
  const onChangeLastname = useCallback((e) => {
    setLastname(e.target.value)
  }, [])
  const onShortenLastnameChange = useCallback((e) => {
    setShortenLastnameChecked(e.target.checked)
  }, [])
  const onChangeEmail = useCallback((e) => {
    setReminderEmail(e.target.value)
  }, [])
  const onReceiveBetRemindersChange = useCallback((e) => {
    setReceiveBetRemindersChecked(e.target.checked)
  }, [])
  const onMarketingOptInChange = useCallback((e) => {
    setMarketingOptInChecked(e.target.checked)
  }, [])
  const onNewsletterOptInChange= useCallback((e) => {
    setNewsletterOptInChecked(e.target.checked)
  }, [])
  const onOptInChecked = useCallback((e) => {
    setOptInChecked(e.target.checked)
  }, [])

  return (
    <div>
      {variant === "complete_profile" && <>
        <h3>
          <label htmlFor={'firstname'}>{I18n.t('complete_profile.firstname_label')}</label>
        </h3>
        <p>
          <input
            type='text'
            name='firstname'
            id='firstname'
            value={firstname}
            onChange={onChangeFirstname}
            autoFocus
            maxLength={32}
            className={'input input--higher' + (firstnameErrorMsg ? ' input--error' : '')}
            placeholder={I18n.t('complete_profile.firstname_placeholder')} />
          {firstnameErrorMsg &&
            <span className='form__error form__error--centered'>{firstnameErrorMsg}</span>}
        </p>
        <p>
          <input
              type='text'
              name='lastname'
              id='lastname'
              value={lastname}
              onChange={onChangeLastname}
              autoFocus
              maxLength={32}
              className={'input input--higher' + (lastnameErrorMsg ? ' input--error' : '')}
              placeholder={I18n.t('complete_profile.lastname_placeholder')} />
          {lastnameErrorMsg &&
              <span className='form__error form__error--centered'>{lastnameErrorMsg}</span>}
        </p>
      </>}
      <p className='completeProfile__checkbox__container'>
        <span>
          <input
              className='checkbox__input'
              type='checkbox'
              name='shortenLastname'
              id='__shortenLastname'
              checked={shortenLastnameChecked}
              onChange={onShortenLastnameChange}
          />
          <label className={'checkbox__label'} htmlFor={'__shortenLastname'} />
        </span>
        <span>
          <span dangerouslySetInnerHTML={{ __html: I18n.t('complete_profile.shorten_lastname') }} />
        </span>
      </p>
      <p className='completeProfile__checkbox__container'>
        <span>
          <input
              className='checkbox__input'
              type='checkbox'
              name='receiveBetReminders'
              id='__receiveBetReminders'
              checked={receiveBetRemindersChecked}
              onChange={onReceiveBetRemindersChange}
          />
          <label className={'checkbox__label'} htmlFor={'__receiveBetReminders'} />
        </span>
        <span>
          <span dangerouslySetInnerHTML={{ __html: I18n.t('complete_profile.receive_bet_reminders') }} />
        </span>
      </p>
      {receiveBetRemindersChecked && <p>
          <input
              type='email'
              name='reminder_email'
              id='reminder_email'
              value={reminderEmail}
              onChange={onChangeEmail}
              onKeyDown={onKeyDownEmail}
              maxLength={64}
              className={'input input--higher' + (emailErrorMsg ? ' input--error' : '')}
              placeholder={I18n.t('complete_profile.email_placeholer')} />
          {emailErrorMsg &&
              <span className='form__error form__error--centered'>{emailErrorMsg}</span>}
        </p>}
      {variant === "complete_profile" && HAS_OPTIN &&
        <p className='completeProfile__checkbox__container'>
          <span>
            <input
              className={'checkbox__input' + (optInRequiredError ? ' checkbox__input--error' : '')}
              type='checkbox'
              name='opt_in_terms'
              id='__optInTerms'
              checked={optinChecked}
              onChange={onOptInChecked}
            />
            <label className={'checkbox__label'} htmlFor={'__optInTerms'} />
          </span>
          <span>
            <span dangerouslySetInnerHTML={{ __html: I18n.t('complete_profile.terms_optin', { terms_url: termsUrl }) }} />
            {optInRequiredError &&
              <span className='form__error'>{I18n.t('profiles.errors.optin_terms_required')}</span>}
          </span>
        </p>}
      {HAS_OPTIN && <p className='completeProfile__checkbox__container'>
          <span>
              <input
                  className='checkbox__input'
                  type='checkbox'
                  name='newsletter_opt_in'
                  id='__newsletterOptIn'
                  checked={newsletterOptInChecked}
                  onChange={onNewsletterOptInChange}
              />
            <label className={'checkbox__label'} htmlFor={'__newsletterOptIn'}/>
          </span>
        <span>
            <span
                dangerouslySetInnerHTML={{__html: I18n.t('complete_profile.newsletter_optin')}}/>
          </span>
      </p>}
      {HAS_OPTIN && <p className='completeProfile__checkbox__container'>
          <span>
              <input
                  className='checkbox__input'
                  type='checkbox'
                  name='marketing_opt_in'
                  id='__marketingOptIn'
                  checked={marketingOptInChecked}
                  onChange={onMarketingOptInChange}
              />
            <label className={'checkbox__label'} htmlFor={'__marketingOptIn'}/>
          </span>
        <span>
            <span
                dangerouslySetInnerHTML={{__html: I18n.t('complete_profile.marketing_optin', {terms_url: termsUrl})}}/>
          </span>
      </p>}
      <p>
        {I18n.t('complete_profile.mandatory')}
      </p>
      <p className='completeProfile__submitWrapper'>
        <Button
          primary
          disabled={submitting}
          onClick={submit}>{I18n.t('complete_profile.submit')}</Button>
      </p>
    </div>
  )
}

export default NicknameForm
