import React from 'react'

const flagContext = require.context('@images/flags', true, /.svg$/)

const FlagImg = ({ item }) => {
  return (
    <div className="selector__selectItem__flagWrapper">
      <img className="selector__selectItem__flag" src={flagContext(`./${item.countryCode}.svg`)} />
    </div>
  )
}

export default FlagImg