import React, { useState } from 'react'
import Select from 'react-select'
import I18n from 'i18n-js'

import { Header, Wrapper, Footer, Body, MetaInfo } from "./ui"
import { PointsAsDigits } from '@root/bet_utils/CirclePicker'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import { addSecondsLeftToBet } from '@root/bet_utils/use_deadline_countdown'
import { textPickPresenter } from './presenter'
import PostponedInfo from '@root/bet_utils/PostponedInfo'
import CancelledInfo from '@root/bet_utils/CancelledInfo'
import BetContext from '@root/bet_utils/context/bet'
import customStyles from '../SelectRaceweek/styles'
import savePicks from '@root/bet_utils/save_picks'
import { DropdownIndicator } from '../SelectRaceweek/select-components'
import DisplaySaveResult, { createOkResult, createErrorResult } from '@root/bet_utils/DisplaySaveResult'
import { changePrediction } from '@root/tracking/signals'

const TextSelection = ({ bet, authenticity_token: authenticityToken }) => {
  addSecondsLeftToBet(bet) // seconds left automatically calculated

  const [ picks, setPicks ] = useState(bet.picks || [])
  bet.picks = picks // so presenters work with updated picks
  const [ displaySaveResult, setDisplaySaveResult ] = useState(null)

  const changePickVal = (newAnswer) => {
    const newPicks = [ (newAnswer && newAnswer.id) ? newAnswer.id : 0 ]
    setPicks(newPicks) // force component update
    changePrediction.dispatch({})
    savePicks(bet, authenticityToken, newPicks,
      /* OK    */ (msg) => { setDisplaySaveResult(createOkResult(msg)) },
      /* ERROR */ (errorMsg) => { setDisplaySaveResult(createErrorResult(errorMsg)) })
  }

  return (
    <BetContext.Provider value={bet}>
      <Wrapper>
        <Header />
        <CancelledInfo />
        <PostponedInfo />
        <MetaInfo bet={bet} />
        <Body>
          <div className={'textSelectionBet__question'}>{ bet.question }</div>
          {betStatusPresenter.canEdit(bet) &&
            <div className={'textSelectionBet__dropdownWrapper'}>
              <Select
                value={textPickPresenter.isBetPicked(bet) ? textPickPresenter.pickedAnswer(bet) : null}
                placeholder={I18n.t('bet.text_selection.please_pick')}
                options={textPickPresenter.answers(bet)}
                onChange={changePickVal}
                styles={customStyles}
                getOptionLabel={(answer) => answer.name}
                getOptionValue={(answer) => answer.id}
                isSearchable={false}
                components={{ DropdownIndicator }} />
            </div>}
          {!betStatusPresenter.canEdit(bet) &&
            <div className={'textSelectionBet__pickedAnswer__wrapper'}>
              <div className={'textSelectionBet__pickedAnswer__label'}>
                {betStatusPresenter.isFromOther(bet) ? I18n.t('bet.text_selection.others_pick') : I18n.t('bet.text_selection.your_pick')}
              </div>
              <div className={'textSelectionBet__pickedAnswer__value'}>
                {textPickPresenter.prettyPick(bet)}
              </div>
            </div>}
          {betStatusPresenter.isMatchOverAndScored(bet) &&
            <div className={'textSelectionBet__score'}>
              <PointsAsDigits score={bet.total_score || 0} />
            </div>}
        </Body>
        <DisplaySaveResult classPrefix='scoreBet' saveResult={displaySaveResult} />
        {betStatusPresenter.questionWaitingForScoring(bet)
          ? <Footer>
              {I18n.t('bet.text_selection.await_scoring')}
            </Footer>
          : (betStatusPresenter.isMatchOverAndScored(bet) &&
            <Footer>
              <h3>{I18n.t('bet.text_selection.correct_answer')}</h3>
              <div className={'textSelectionBet__correctAnswer'}>
                {(textPickPresenter.correctAnswer(bet) || {}).name || '-'}
              </div>
            </Footer>)
        }
      </Wrapper>
    </BetContext.Provider>
  )
}

export default TextSelection
